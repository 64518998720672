.h1, .h2, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: normal;
    line-height: normal;
}

p {
    margin: 0;
    padding: 0;
}